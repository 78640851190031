import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { colors, md } from '../common'

export default () => (
  <Layout>
  <SEO title="Shipping Policy" />
  <Wrap>
  <h1>Online Shipping Policy</h1>
  <div>
    <h2>Online Flat Rate $5 Shipping</h2>
  <p>
    Purchase any item available for sale online and we will ship it to you anywhere in the United States for $5. Underwood’s strives to provide fast and safe shipping with securely packaged products. For your protection and insurance of the item, we do not ship over the weekend. We also do not ship internationally at this time.
  </p>
  <h3>Shipping Methods</h3>
  <p>
    Flat Rate $5 Shipping via USPS:  Please allow for the standard USPS shipping time (usually 2-5 days from the ship date).
  </p>
    <p>Please note: We only ship your online order once it has passed through our thorough quality control. Jewelry and watches will be shipped in one to two days, depending on the quality control schedule of the item(s).</p>
    <h3>For your protection, Underwood’s always:</h3>
     <ol>
      <li> Requires that all deliveries be made to a physical business or home address</li>
      <li> Underwood’s does not offer any shipping services outside of the United States</li>
      <li> Any credit card orders must be shipped to a valid address on file with the credit card company.</li>
    </ol>
  </div>
  </Wrap>
  </Layout>
)

const Wrap = styled.div`
  padding: 4rem 8rem;

  h1 {
    color:${colors.green};
    text-align:center;
    text-transform:uppercase;
    padding-bottom:2rem;
  }

  li {
    padding: .5rem 0;
  }

  ${md} {
    padding:1rem;
  }
`
